.logo {
    display: block;
    flex-shrink: 0;
    width: 70px;
    height: 42px;
}

    @media (max-width: 1200px) {.logo {
        width: calc(65px + (61 - 65) * ((100vw - 375px) / (1200 - 375)));
        height: calc(31px + (26 - 16) * ((100vw - 375px) / (1200 - 375)))
}


        @media (max-width: 767.98px) {.logo {
            width: 200px
}
        }
    }

    .logo__img {
        width: 100%;
        height: 100%;
    }

.logo--white {
    width: 70px;

    fill: #ffffff;
}

@media (max-width: 991px) {

body._show-navbar .logo {
            width: 200px
    }
        }