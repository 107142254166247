.promo {
    position: relative;
}
    .promo__img {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }
    @media (max-width: 991.98px) {
    .promo__img {
            width: auto;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            display: block
    }
        }
