/* $minWidth: 320px;
$maxWidth: 1600;
$maxWidthContainer: 1200;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98; */
/* $md4: 479.98;
$white: #ffffff; 
$black: #282828;
$lightPurple: #8246b8;
$purple: #59358c;
$lightpurple: #f3e5ff;
$lightgray: #f8f8f8;
$gray: #686868;
$green: #44bc39;
$yellow: #ffc56e;
$red: #d71a1a; */
/* @custom-media --md3 (max-width: 767.98px); */
.header {
    margin-bottom: 32px;
}
@media (max-width: 1200px) {
.header {
        margin-bottom: calc(
            18px + (32 - 18) * ((100vw - 375px) / (1200 - 375))
        )
}
    }
.header__logo {
        margin-right: 56px;
    }
@media (max-width: 767.98px) {
.header__logo {
            order: 2;
            margin: 0 auto
    }
        }
.header__menu-icon {
        margin-right: 56px;
    }
@media (max-width: 767.98px) {
.header__menu-icon {
            order: 1;
            margin-right: 0
    }
        }
.header__search {
        flex-grow: 1;
        margin-right: 56px;
    }
@media (max-width: 767.98px) {
.header__search {
            flex-grow: 0;
            order: 2;
            margin-right: 2px
    }
        }
.header__login {
        order: 2;
    }
.header__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
@media (max-width: 767.98px) {
.header-top {
        padding: 10px 15px
}
    }
