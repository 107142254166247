.about-project-advertising__title {
        margin-bottom: 18px;

        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        color: #59358c;
    }
    .about-project-advertising__text {
        margin-bottom: 16px;

        font-size: 18px;
        line-height: 25px;
        color: #282828;
    }
    .about-project-advertising__text-link {
        display: block;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #59358c;
    }
