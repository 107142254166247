.navbar {
    background: #282828;
    padding: 0 16px 50px;
    position: fixed;
    /* top: 195px; */
    top: calc(25vw + 64px);
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 2000;
    display: none;
}

@media (max-width: 991px) {
    body._show-navbar .navbar {
        top: calc(84px);
    }
}

@media (max-width: 767px) {
    body._show-navbar .navbar {
        top: calc(64px);
    }
}

.navbar__menu ul {
    margin: 0;
}

.navbar__menu ul li {
    margin: 0;
    border-bottom: 1px solid rgba(104, 104, 104, 0.2);
}

.navbar__menu a {
    display: block;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 20px 0;
}

.navbar__social {
    padding-top: 50px;
    padding-bottom: 24px;
    padding-left: 30px;
    padding-right: 30px;
}

.navbar__social ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.navbar__social ul li {
    padding: 0 8px 16px;
}

.navbar__social ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #59358c;
    border-radius: 40px;
}

.navbar__social ul li a svg {
    fill: #ffffff;
}

.navbar__social-icon-vk {
    width: 23px;
    height: 12px;
}

.navbar__social-icon-facebook {
    width: 12px;
    height: 25px;
}

.navbar__social-icon-odn {
    width: 14px;
    height: 25px;
}

.navbar__social-icon-telegram {
    width: 22px;
    height: 18px;
}

.navbar__social-icon-youtube {
    width: 20px;
    height: 13px;
}

.navbar__social-icon-rrs {
    width: 20px;
    height: 20px;
}

.navbar__social-icon-mail {
    width: 23px;
    height: 17px;
}

.navbar__social-icon-instagram {
    width: 18px;
    height: 18px;
}

.navbar__social-icon-twitter {
    width: 20px;
    height: 17px;
}

.navbar__social-icon-zen {
    width: 40px;
    height: 40px;
}

.navbar__info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.navbar__logo {
    margin-bottom: 17px;
    height: 16px;
    display: flex;
    align-items: center;
}

.navbar__logo svg {
    width: 170px;
    fill: #fff;
}

.navbar__copy {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
}

body._show-navbar {
    overflow: hidden;
}

body._show-navbar .navbar {
    display: block;
}

body._show-navbar .header {
    background: #59358c;
}

body._show-navbar .search__icon {
    fill: #fff;
}

body._show-navbar .login__icon {
    fill: #fff;
}

.header__logo .logo__img._mobile {
    display: none;
}

body._show-navbar .header__logo .logo__img {
    display: none;
}

body._show-navbar .header__logo .logo__img._mobile {
    display: block;
    fill: #fff;
}

.menu-icon__icon._close {
    display: none;
}

body._show-navbar .menu-icon__icon {
    display: none;
}

body._show-navbar .menu-icon__icon._close {
    display: block;
}

.header-top .header__menu-icon {
    display: none;
}

@media screen and (max-width: 768px) {
    .header-top .header__menu-icon {
        display: block;
    }
}

@media (max-width: 991.98px) {
    .header-top .header__menu-icon {
        display: block;
    }
}
