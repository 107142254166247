.about-project-contacts {
    display: flex;
    flex-direction: column;
}
    .about-project-contacts__title {
        margin-bottom: 19px;

        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        color: #59358c;
    }
    .about-project-contacts__item {
        display: flex;
        margin-bottom: 16px;

        font-size: 16px;
        line-height: 21px;
        color: #282828;
    }
    .about-project-contacts__img {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-right: 8px;

        fill: #59358c;
    }
    .about-project-contacts__social {
        margin-top: 15px;
    }
