.person {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 270px));
    grid-auto-flow: auto;
    grid-template-rows: auto;
    row-gap: 32px;
    column-gap: 30px;
    padding-top: 70px;
    padding-bottom: 52px;
}

    @media (max-width: 1200px) {.person {
        padding-top: calc(64px + (70 - 64) * ((100vw - 375px) / (1200 - 375)));
        padding-bottom: calc(
            48px + (104 - 48) * ((100vw - 375px) / (1200 - 375))
        )
}
    }

    @media (max-width: 991.98px) {.person {
        grid-template-columns: 1fr 1fr
}
    }

    @media (max-width: 767.98px) {.person {
        display: flex;
        flex-direction: column
}
    }

    .person__page-title {
        grid-column: 1 / 3;
    }

    @media (max-width: 991.98px) {

    .person__page-title {
            grid-column: 1 / 2
    }
        }

    .person__more {
        grid-column: 3 / 5;
        align-self: end;
        justify-self: end;
        margin-bottom: 11px;
    }

    @media (max-width: 991.98px) {

    .person__more {
            grid-column: 2 / 3
    }
        }

    @media (max-width: 767.98px) {

    .person__more {
            order: 2;
            align-self: flex-start
    }
        }

    .person__item {
    }

    .person__img {
    }
