.container-95735 .list-container-item {
        width: 170px !important;
        margin-right: 30px !important;
    }
    .container-95735__header {
        display: none !important;
    }

.media {
    margin: 70px 0 104px 0;
}

@media (max-width: 1200px) {

.media {
        padding-top: calc(50px + (70 - 50) * ((100vw - 375px) / (1200 - 375)));
        padding-bottom: calc(
            50px + (104 - 50) * ((100vw - 375px) / (1200 - 375))
        )
}
    }

.media__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 34px;
    }

.media__list {
        display: flex;
        overflow-x: auto;
    }

.media__item {
        flex: 1 0 170px;
    }

.media__item:not(:last-child) {
            margin-right: 30px;
        }

.media__link {
        position: relative;

        display: block;
        height: 220px;
        padding: 10px 12px;
    }

.media__link::before,
        .media__link::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 3;

            transition: all 0.4s ease;
        }

.media__link::before {
            background: linear-gradient(
                180deg,
                rgba(89, 53, 140, 0.15) 42.05%,
                #282828 100%
            );
            opacity: 1;
        }

.media__link::after {
            background: linear-gradient(
                180deg,
                rgba(89, 53, 140, 0) 0%,
                #59358c 100%
            );
            opacity: 0;
        }

.media__link:hover::before {
            opacity: 0;
        }

.media__link:hover::after {
            opacity: 1;
        }

.media__img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        height: 100%;

        object-fit: cover;
    }

.media__card-title {
        position: absolute;
        bottom: 10px;
        left: 12px;
        z-index: 4;

        max-width: 138px;

        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
    }
