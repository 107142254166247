.articles {
    display: grid;
    grid-template-columns: minmax(300px, 770px) minmax(200px, 343px);
    grid-template-rows: auto 349px 348px;
    column-gap: 54px;
    row-gap: 35px;
    padding-top: 70px;
}

    @media (max-width: 1200px) {.articles {
        padding-top: calc(0px + (70 - 0) * ((100vw - 375px) / (1200 - 375)))
}
    }

    @media (max-width: 991.98px) {.articles {
        grid-template-rows: auto auto auto
}
    }

    @media (max-width: 991.98px) {.articles {
        column-gap: 52px;
        grid-template-columns: minmax(300px, 343px) minmax(200px, 343px)
        /* display: flex;
        flex-direction: column;

        row-gap: 18px; */
}
    }

    @media (max-width: 767px) {.articles {
        display: flex;
        flex-direction: column;

        row-gap: 18px
}
    }

    .articles__page-title {
        grid-column: 1 / 2;
    }

    .articles__more {
        grid-column: 2 / 3;

        align-self: end;
        justify-self: end;
        margin-bottom: 11px;
    }

    @media (max-width: 767.98px) {

    .articles__more {
            display: none
    }
        }

    .articles__post {
        grid-column: 1 / 2;
    }

    .articles__promo {
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        /* @media (max-width: 991.98px) {

        } */
    }
