.newsfeed {
    display: grid;
    grid-template-columns: minmax(300px, 506px) minmax(200px, 300px) minmax(
            200px,
            300px
        );
    grid-template-rows: auto;
    column-gap: 32px;
    row-gap: 37px;
    padding-top: 78px;
    padding-bottom: 82px;

    border-bottom: 1px solid rgba(104, 104, 104, 0.2);
}

    @media (max-width: 1200px) {.newsfeed {
        padding-top: calc(44px + (78 - 44) * ((100vw - 375px) / (1200 - 375)));
        padding-bottom: calc(
            36px + (82 - 36) * ((100vw - 375px) / (1200 - 375))
        )
}
    }

    @media (max-width: 991.98px) {.newsfeed {
        grid-template-columns: minmax(200px, 400px) minmax(200px, 400px)
}
    }

    @media (max-width: 550px) {.newsfeed {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        border-bottom: none
}
    }

    .newsfeed__page-title {
        grid-column: 1 / 2;
    }

    .newsfeed__more {
        grid-column: 2 / 4;

        align-self: end;
        justify-self: end;
        margin-bottom: 11px;
    }

    @media (max-width: 991.98px) {

    .newsfeed__more {
            grid-column: 2 / 3;
            align-self: flex-start
    }
        }

    @media (max-width: 550px) {

    .newsfeed__more {
            order: 2
    }
        }

    .newsfeed__post-newsfeed--main {
        grid-row: span 2;
    }

    @media (max-width: 991.98px) {

    .newsfeed__post-newsfeed--main {
            display: none
    }
        }
