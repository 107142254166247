.login__btn {
        display: flex;
        align-items: center;

        background: transparent;
    }
    .login__icon {
        margin-right: 8px;

        width: 24px;
        height: 24px;

        fill: #59358c;
    }
    .login__title {
        font-size: 16px;
        line-height: 110%;

        color: #686868;
    }
    @media (max-width: 767.98px) {
    .login__title--mobile {
            display: none
    }
        }
