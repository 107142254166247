.footer-menu {
    padding: 6px 0;
}

    .footer-menu__title {
        margin-bottom: 15px;

        font-size: 14px;
        line-height: 110%;

        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #ffffff;
    }

    .footer-menu__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        gap: 13px 0;
    }

    .footer-menu__item {
    }

    .footer-menu__link {
        font-size: 16px;
        line-height: 110%;

        color: #ffffff;
    }
