/* flex-grow: 1; */
    .page_align_center {
        text-align: center;
    }
    /* flex: 1 0 auto; */
    .page__container {
        max-width: 760px;
        margin: 25px auto 80px;
    }
    @media (max-width: 980px) {
    .page__container {
            padding: 0 15px
    }
        }
    .page__container_clear::after {
            content: '';
            clear: both;
            display: table;
        }
    .page__container-more {
        margin-top: 40px;
    }
    .page__container h2 {
        text-align: left;
        padding-left: 10px;
    }
    @media (max-width: 980px) {
    .page__container h2 {
            text-align: center
    }
        }
    @media (max-width: 980px) {
    .page__container h2 > .rect {
                margin: 0 auto
        }
            }

/* flex: 1 1 auto; */

.page__articles {
        margin-bottom: 32px;
    }

@media (max-width: 1200px) {

.page__articles {
            margin-bottom: calc(
                13px + (32 - 13) * ((100vw - 375px) / (1200 - 375))
            )
    }
        }

/* margin-bottom: 32px; */

@media (max-width: 991.98px) {

.page__subscription {
            display: none
    }
        }
