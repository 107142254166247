.article-comments__list {
        display: flex;
        flex-direction: column;

        row-gap: 24px;
    }

.comment {
    display: flex;
    column-gap: 16px;
}

.comment__img {
        width: 64px;
        height: 64px;
        border-radius: 100%;

        overflow: hidden;
    }

.comment__img img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

.comment__content {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

.comment__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
    }

.comment__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;

        display: flex;

        color: #282828;
    }

.comment__date {
        font-size: 14px;
        line-height: 120%;

        color: #282828;

        opacity: 0.5;
    }

.comment__text {
        font-size: 16px;
        line-height: 130%;

        color: #282828;
    }

.comment__likes {
        margin-left: -10px;
    }

.article-comments__form {
    position: relative;
}

.article-comments__alert {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: -30px;
    color: #59358c;
}
