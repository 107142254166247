.search__form {
        position: relative;

        display: flex;
        flex-shrink: 0;
        align-items: center;

        width: 100%;
        height: 44px;
    }
    .search__input {
        width: 100%;
        height: 100%;
        padding: 11px 50px 9px 16px;

        font-size: 16px;
        line-height: 110%;

        border: 1px solid rgba(104, 104, 104, 0.2);
        border-radius: 4px;
    }
    @media (max-width: 767.98px) {
    .search__input--mobile {
            display: none
    }
        }
    .search__btn {
        position: absolute;
        top: 14px;
        right: 17.5px;

        background: transparent;
    }
    @media (max-width: 767.98px) {
    .search__btn {
            position: static;

            margin-top: 5px;
            margin-right: 15px
    }
        }
    .search__icon {
        width: 17.5px;
        height: 17.5px;

        fill: #59358c;
    }

.search--materials .search__input {
            position: relative;
        }

@media (max-width: 767.98px) {

.search--materials .search__btn {
                position: absolute;

                margin: 0
        }
            }

.search--authors .search {
        width: 100%;
    }

@media (max-width: 767.98px) {

.search--authors .search__btn {
                position: absolute;

                margin: 0
        }
            }
