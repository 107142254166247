.footer {
    background: #282828;
}

    .footer__content {
        padding: 53px 16px;
        display: grid;
        grid-template-columns: 177px 220px 236px 171px;
        column-gap: 123px;
    }

    @media (max-width: 1600px) {

    .footer__content {
            column-gap: calc(
                50px + (123 - 50) * ((100vw - 992px) / (1600 - 992))
            )
    }
        }

    @media (max-width: 991.98px) {

    .footer__content {
            grid-template-columns: 177px 1fr 1fr;
            grid-template-rows: auto auto
    }
        }

    @media (max-width: 767.98px) {

    .footer__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px 55px
    }
        }

    @media (max-width: 767.98px) {

    .footer__content {
            padding: 40px 65px 40px 55px
    }
        }

    @media (max-width: 767.98px) {

    .footer__info {
            order: 2;

            display: flex;
            flex-direction: column;
            align-items: center
    }
        }

    .footer__logo {
        margin-bottom: 14px;
    }

    .footer__logo img {
            width: 50px;
            height: auto;
        }

    @media (max-width: 479.98px) {

    .footer__logo {
            margin-bottom: 18px
    }
        }

    .footer__copy {
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 0.6px;

        color: #ffffff;
    }

    @media (max-width: 767.98px) {

    .footer__copy {
            text-align: center;
            width: 180px
    }
        }

    @media (max-width: 479.98px) {

    .footer__copy {
            font-size: 14px;
            line-height: 20px;
            margin-left: 8px;
            letter-spacing: 0.4px
    }
        }

    @media (max-width: 767.98px) {

    .footer__menu {
            display: none
    }
        }

    .footer__menu--project {
        margin-left: -25px;
    }

    .footer__social {
        margin-left: -18px;
        padding: 6px 0;
    }

    @media (max-width: 991.98px) {

    .footer__social {
            width: 200px;
            margin-left: 0
    }
        }

    @media (max-width: 767.98px) {

    .footer__social {
            order: 1;
            margin-bottom: 32px;
            padding: 8px 0;
            width: 256px
    }
        }
