.scroll-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    z-index: 1000;
}

.scroll-top._show {
    pointer-events: all;
    opacity: 1;
}
