.more {
    display: flex;
    align-items: center;
    padding-top: 29px;
    font-size: 18px;

    line-height: 140%;

    color: #ffffff;
    fill: #ffffff;

    transition: all 0.2s linear;
}

    @media (max-width: 1200px) {.more {
        padding-top: calc(0px + (29 - 0) * ((100vw - 375px) / (1200 - 375)));
        font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1200 - 375)))
}
    }

    .more:hover {
        color: #8246b8;
    }

    .more:hover .more__icon {
        fill: #8246b8;
    }

    .more__icon {
        margin-left: 10px;
        width: 18.21px;
        height: 10.71px;

        fill: inherit;

        transition: all 0.2s linear;
    }

.news__more {
    padding: 0;
    color: #ffffff;
    fill: #ffffff;
}

.news__more:hover {
        color: #ffffff;
        text-decoration: underline;
    }

.news__more:hover .more__icon {
        fill: #ffffff;
    }

.more--purple {
    color: #59358c;
    fill: #59358c;
}

.more--black {
    color: #282828;
    fill: #282828;
}
